<div
	class="cart p-1 rounded-2xl overflow-hidden block h-full"
	[ngClass]="{
		'cursor-pointer': !!route()
	}"
>
	<div
		class="container flex gap-3 flex-wrap lg:flex-nowrap p-1 relative"
		[ngClass]="axis() === 'vertical' ? 'flex-col items-center' : ''"
		(click)="navigateTo(route())"
	>
		<div
			class="relative rounded-lg m-2 overflow-hidden cursor-pointer mx-auto lg:mx-0"
			[ngClass]="{'w-[80vw]': type === 'academyandplayground'}"
		>
			<img
				[ngClass]="{
					'photo-container p-1 rounded-lg overflow-hidden flex justify-between items-center gap-6': type === 'video',
					'w-full h-full rounded-lg': type !== 'video'
				}"
				[src]="image()"
				alt="Card Image"
				[style.width.px]="width"
				[style.height.px]="height"
			/>
			<ng-content select="[imageOverlay]"></ng-content>
		</div>

		<div
			class="content flex-grow flex flex-col w-full justify-center lg:justify-start"
		>
			<ng-content select="[preContent]"> </ng-content>
			<!-- Title -->
			<span class="title-wrapper block pb-2">
				<!-- Title -->
				<p
					[ngClass]="{
						'mt-2 pt-3 ml-2 text-3xl font-bold pb-4 text-yellow-300 text-nowrap': type === 'academyandplayground' ,
						' text-white capitalize text-lg font-bold block': type !== 'academyandplayground'
					}"
					class="title"
				>
					{{ title() }}
				</p>
			</span>
	
			<span class="post-content text-xs">
				<ng-content select="[postContent]"> </ng-content>
			</span>
		</div>
	</div>
</div>

<ng-template #itemData let-primeIcon="primeIcon" let-value="value">
	<span class="flex items-center">
		<!-- Icon -->
		<i
			*ngIf="primeIcon"
			class="pi text-yellow-300 "
			style="font-size: 25px"
			[ngClass]="primeIcon ? primeIcon : ''"
		></i>
		<!-- Data -->
		<span class="px-2  text-white">{{ value }}</span>
	</span>
</ng-template>
