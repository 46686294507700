import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";


export class CustomValidators  {


        static required(): ValidatorFn {
          return (control: AbstractControl): ValidationErrors | null => {
              return control.value ? null : { required: true };
          }
      }


      static minMaxLengthValidation(min: number, max: number): (control: AbstractControl) => ValidationErrors | null {
          return (control: AbstractControl): ValidationErrors | null => {
              const value = control.value || '';
              const actualLength = value.length;
              const isValid = actualLength >= min && actualLength <= max;

              return isValid ? null : { patternValidator: { actualLength } };
          };
      }

      static minMaxValueValidation(min:number,max:number): (control: AbstractControl) => ValidationErrors | null {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value || 0;
            if(value > max){
                control.setValue(max);
            }

            const isValid = value >= min && value <= max;
            return isValid ? null : { patternValidator: { actualLength: value } };
        };
    }


      static patternValidation(pattern: RegExp): (control: AbstractControl) => ValidationErrors | null {
          return (control: AbstractControl): ValidationErrors | null => {
              const value = control.value || '';
              const isValid = pattern.test(value);
              return isValid ? null : { patternValidator: true };
          };
    }

      static egyptianMobileNumberValidation():ValidatorFn{
        return (control:AbstractControl):ValidationErrors | null => {
            const mobileRegex = /^(010|011|012|015)\d{8}$/;
            return control.value && mobileRegex.test(control.value) ? null : { mobileNumberValidation: true }
        }
    }


      static englishOrArabicOnly(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value || '';
            
            const arabicRegex = /^[\u0621-\u064A\u0660-\u0669]+$/;
            const englishRegex = /^[a-zA-Z]+$/;
            const hasNumbers = /\d/.test(value);
            if (hasNumbers) {
                return { containsNumbers: true };
            }

            if (arabicRegex.test(value)) {
                return arabicRegex.test(value) ? null : { languageMismatch: true };
            }

            if (englishRegex.test(value)) {
                return englishRegex.test(value) ? null : { languageMismatch: true };
            }

            return { languageMismatch: true };
        };
    
    }


          


    }



    export enum CustomValidationType {
        required = 'required',
        patternValidator = 'patternValidator',
        mobileNumberValidation = 'mobileNumberValidation',
        languageMismatch = 'languageMismatch',
        containsNumbers = 'containsNumbers'
    }


    export enum VariablesValidation {
      IBAN_minLength =15,
      IBAN_maxLength = 34,
      // add_branch
      branch_name_en_minLength = 3,
      length_1 = 1,
      length_2 = 2,
      length_3 = 3,
      length_6 = 6,
      length_10 = 10,
      length_15 = 15,
      length_20 = 20,
      length_49 = 49,
      length_50 = 50,
      length_100 = 100,
      length_199 = 199,
      length_500 = 500,
      length_999 = 999,
      length_1000 = 1000,
      value_1 = 1,
      value_7 =7,
      value_14 = 14,
      value_20 = 20,
      value_35 = 35,
      value_52 = 52,
      value_210 = 210,
      value_250 = 250,
      value_999 = 999,
      value_1000 = 1000,
      value_10000 = 10000,
      branch_name_en_maxLength = 50,
      account_name_en_minLength = 3,
      account_name_en_maxLength = 50,
      name_ar_add_branch_minLength = 3,
      name_ar_add_branch_maxLength = 49,
      name_en_add_branch_minLength = 3,
      name_en_add_branch_maxLength = 49,
      address_add_branch_minLength = 3,
      address_add_branch_maxLength = 199,
      number_of_playgrounds_add_branch_min=1,
      number_of_playgrounds_add_branch_max=100,
      contact_name_add_branch_minLength = 3,
      contact_name_add_branch_maxLength = 50,
      slug_minLength = 3,
      slug_maxLength = 199,
      description_add_branch_minLength = 10,
      description_add_branch_maxLength = 500,
      number_of_players_add_branch_min = 1,
      number_of_players_add_branch_max = 1000,
      number_of_championships_add_branch_min = 1,
      number_of_championships_add_branch_max = 100,
      // Registration and Login
      password_minLength = 6,
      password_maxLength = 15,
      register_name_en_minLength = 3,
      register_name_en_maxLength = 49,
      register_contact_name_minLength = 3,
      register_contact_name_maxLength = 49,
      } 


    export const PatternValidation = {

      SlugPattern : /^[a-z0-9]+$/,
      IBAN_PATTERN : /^[A-Z]{2}[0-9]{2}[A-Z0-9]{11,30}$/,
	  SWIFT_PATTERN :  /^[A-Z]{4}[A-Z]{2}[A-Z0-9]{2}([A-Z0-9]{3})?$/,
      ACCOUNT_NAME_PATTERN : /^[\u0621-\u064A\u0660-\u0669a-zA-Z\s\-,]{3,50}$/ ,
      elevenDigitsNumber : /^[0-9]{11}$/,
      // '^[a-z]{3}[a-z0-9]*$'
      Slug : /^[a-z]{3}[a-z0-9]*$/,
      googleMapsLink: /https?:\/\/(www\.)?(google\.(com|[a-z]{2,3})\/maps|goo\.gl\/maps|maps\.app\.goo\.gl)\/?.*?/,
      containsMaps: /maps/,
      passwordPattern : /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])(?!.*(.)\1{2}).{8,}$/,
      passwordPatternEasy : /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_{|}~]).{8,}$/
    } as const;









