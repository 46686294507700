<div class="location-card-container flex flex-col h-full">
	<!-- Upper row: Map -->
	<div class="map-container">
	  <div class="map-frame">
		<div id="map"></div>
	  </div>
	</div>
  
	<!-- Bottom row: Details -->
  
	<!-- Address -->
	<div class="grid grid-cols-1 justify-center p-2">
	  <p class="text-s text-fontColor break-all">{{ locationData.address }}</p>
	</div>
  
	<!-- Mobile Number and Get Direction Button -->
	<div class="details-container grid grid-cols-2 items-center justify-between p-2">
	  <!-- Mobile Number (left-aligned) -->
	  <div>
		<p class="text-s text-textYellow">{{ locationData.mobile_number }}</p>
	  </div>
  
	  <!-- Get Direction Button (right-aligned) -->
	  <div>
		<a href="{{ locationData.location_url }}" target="_blank"
		   [ngClass]="{'pointer-events-none': !locationData.location_url}"
		   class="w-30 h-10 bg-semiLightGray border border-fontColor text-base text-fontColor rounded-md shadow-md hover:border-lightBlue hover:bg-lightBlue hover:text-fontColor flex items-center justify-center">
		  {{ "SPORT_ACADEMIES.Get_Direction" | translate }}
		</a>
	  </div>
	</div>
  </div>
  