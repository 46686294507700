import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewChild,
	inject,
} from "@angular/core";
import {
	FormBuilder,
	FormGroup,
	ValidationErrors,
	Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { setCookie } from "@sportyano/core/common/utils/cookies-controller";
import { PlayerService } from "@sportyano/core/services/playerProfile/player.service";
import { ToasterMessagesService } from "@sportyano/core/services/toaster-messages/toaster-messages.service";
import { CustomValidationType, CustomValidators, VariablesValidation } from "@sportyano/shared/directives/custom-validation-reactiveForm";
import intlTelInput from "intl-tel-input";

@Component({
	selector: "app-change-mobile",
	templateUrl: "./change-mobile.component.html",
	styleUrl: "./change-mobile.component.scss",
})
export class ChangeMobileComponent implements OnInit {
	@ViewChild("phoneInput") set phoneInputRef(ref: ElementRef) {
		if (ref) {
			this.phoneInput = ref;
			this.initializePhoneNumberInput();
		}
	}
	private phoneInput!: ElementRef;

	private iti: any;
	protected changeMobileForm: FormGroup;
	private _fb = inject(FormBuilder);
	public CustomValidationType = CustomValidationType
	private _translateService = inject(TranslateService);
	@Input() display: boolean;
	@Input() phoneNumber: string | undefined;
	@Output() onEdit: EventEmitter<void> = new EventEmitter<void>();
	@Output() onCancle: EventEmitter<void> = new EventEmitter<void>();
	private _toaster = inject(ToasterMessagesService);
	private _router = inject(Router)
	constructor(private services: PlayerService) {}
	ngOnInit(): void {
		this.buildChangeMobileForm();
	}

	private initializePhoneNumberInput() {
		const inputElement = this.phoneInput.nativeElement;
		inputElement.addEventListener("keyup", () => {
			this.changeMobileForm.controls["phone_number"].setValue(
				inputElement.value
			);
		});

		// inputElement.addEventListener("countrychange", () => {
		// 	this.changeMobileForm.controls["phone_number"]
		// });

		this.iti = intlTelInput(inputElement, {
			allowDropdown: true,
			autoInsertDialCode: true,
			autoPlaceholder: "aggressive",
			formatOnDisplay: true,
			initialCountry: "eg",
			nationalMode: true,
			separateDialCode: true,
			placeholderNumberType: "MOBILE",
			utilsScript: "node_modules/intl-tel-input/build/js/utils.js",
		});
	}

	private buildChangeMobileForm() {
		this.changeMobileForm = this._fb.group({
			password: ["", [CustomValidators.required(),CustomValidators.minMaxLengthValidation(VariablesValidation.password_minLength,VariablesValidation.password_maxLength)]],
			phone_number: ["", [CustomValidators.required(), CustomValidators.egyptianMobileNumberValidation()]],
		});
	}

	phoneValidator(): ValidationErrors | null {
		return () => {
			if (!this.phoneInput) {
				return null;
			}
			const isValid = this.iti.isValidNumberPrecise();
			return isValid ? null : { invalidPhone: true };
		};
	}

	update() {
		// if (!this.changeMobileForm.valid) {
		// 	console.log('invalid');
		// 	return;
		// }
		console.log('update');
		// setTimeout(() => {
		// 	this._router.navigateByUrl("/main/otp")
		// 		}, 3000);
		let dataToSend = {
			password: this.changeMobileForm.value.password,
			phone_number: this.changeMobileForm.value.phone_number
				.split(" ")
				.join(""),
		};

		this.services.updatePlayerPhone(dataToSend).subscribe({
			next: (res) => {
				console.log('res',res);
				setCookie("token_M", res.token);
				setTimeout(() => {
					this._router.navigateByUrl("/main/otp")
						}, 1000);
				this.onCancle.emit();
				this.onEdit.emit();
				if(res.status === 422){
					this._toaster.showError(
						this._translateService.instant("otp.otp_error")
					);
				}
				if(res.status === 200) {
					this._toaster.showError(
						this._translateService.instant("otp.otp_successfully_redirect")
					);
				}
			},
		});
	}

	getErrorTooltip(controlName: string): string {
		const control = this.changeMobileForm.get(controlName);
		if (control?.invalid && (control?.dirty || control?.touched)) {
			if (control?.hasError(CustomValidationType.mobileNumberValidation)) {
				return this._translateService.instant(
					"authentication.errors.phoneNumber"
				);
			} else {
				return this._translateService.instant(
					"authentication.errors.phoneNumber"
				);
			}
		}
		return "";
	}

	showTooltip(controlName: string): boolean | null {
		const control = this.changeMobileForm.get(controlName);
		return control && control.invalid && control.dirty && control.touched;
	}

	onCancelEmitter() {
		this.changeMobileForm.reset();
		this.onCancle.emit();
	}
}
