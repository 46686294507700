<p-dialog [(visible)]="loginPopVisible" [dismissableMask]="true" [modal]="true" label="Login"
	[style]="{ width: isMobileScreen ? '90vw' : '50vw' }" [draggable]="false" [resizable]="false">
	<ng-template pTemplate="headless">
		<div class="absolute top-6 right-6">
			<svg _ngcontent-ng-c2706688458="" (click)="closePopup()" width="18" height="18" viewBox="0 0 22 22"
				fill="none" xmlns="http://www.w3.org/2000/svg" class="cursor-pointer">
				<path _ngcontent-ng-c2706688458="" d="M1 1L21 21M21 1L1 21" stroke="#f9c728" stroke-width="4.5"
					stroke-linecap="round" stroke-linejoin="round"></path>
			</svg>
		</div>
		<main class="bg-darkBlue border border-white border-solid rounded-lg">
			<div class="w-full md:w-3/5 mx-auto p-8">
				<div class="w-full">
					<div class="__main-form pt-8">
						<header class="__header">
							<h1 class="uppercase text-start font-semibold text-fontColor lg:text-4xl text-2xl pb-3">
								{{ "authentication.login.form.login" | translate }}
							</h1>
						</header>

						<!-- FORM START -->

						<form [formGroup]="loginForm">
							<div class="__username">
								<label
									for="username"
									class="capitalize text-textYellow text-xs block pb-3"
								>
									{{
										"authentication.login.form.username_email"
											| translate
									}}
								</label>
								<input
									required
									maxlength="11"
									[appCheckLanguage]="'numbers'"
									[dir]="
										translateService.currentLang === 'en'
											? 'ltr'
											: 'rtl'
									"
									id="username"
									class="w-full py-3 px-4 border-none bg-inputFieldBackground rounded-md placeholder-fontColor text-fontColor text-xs shadow-md"
									placeholder="{{
										'authentication.login.form.username_placeholder'
											| translate
									}}"
									required
									formControlName="username"
								/>
								<div
									*ngIf="
										loginForm.get('username')?.invalid &&
										(loginForm.get('username')?.dirty ||
											loginForm.get('username')?.touched)
									"
								>
									<!-- <small
										class="text-primaryRed text-md mt-2"
										*ngIf="loginForm.get('username')?.errors?.['invalidPhone']"
									>
										{{
											"authentication.errors.phoneNumber"
												| translate
										}}
									</small> -->
									<small
										class="text-primaryRed text-md mt-2"
										*ngIf="loginForm.get('username')?.hasError(CustomValidationType.required)"
									>
										{{
											"authentication.errors.required"
												| translate
										}}
									</small>
									<small class="text-primaryRed text-md mt-2" 
										   *ngIf="loginForm.get('username')?.hasError(CustomValidationType.mobileNumberValidation) &&
										   !loginForm.get('username')?.hasError(CustomValidationType.required)">
										{{
											'authentication.errors.egyptian_mobile_number' | translate
										}}
									</small>
									<!-- <small
										class="text-primaryRed text-md mt-2"
										*ngIf="loginForm.get('username')?.errors?.['invalidLength']"
									>
										{{
											"authentication.errors.invalidLength"
												| translate
										}}
									</small> -->
								</div>
							</div>
	
							<div class="__password relative pt-4">
								<label
									for="password"
									class="capitalize text-textYellow text-xs block pb-3"
								>
									{{
										"authentication.login.form.password"
											| translate
									}}
								</label>
								<div class="relative w-full">
									<input
										required
										minlength="6"
										maxlength="15"
										[type]="
											passwordVisibility ? 'text' : 'password'
										"
										id="password"
										class="w-full py-3 px-4 border-none bg-inputFieldBackground rounded-md placeholder-fontColor text-fontColor text-xs shadow-md pr-8"
										placeholder="{{
											'authentication.login.form.password_placeholder'
												| translate
										}}"
										required
										formControlName="password"
									/>
									<div
										class="absolute inset-y-0 ltr:right-4 rtl:left-4 flex items-center pr-3"
									>
										<mat-icon
											class="cursor-pointer text-fontColor"
											(click)="
												passwordVisibility =
													!passwordVisibility
											"
										>
											{{
												passwordVisibility
													? "visibility_off"
													: "visibility"
											}}
										</mat-icon>
									</div>
								</div>
								<div
								*ngIf="
									loginForm.get('password')?.invalid &&
									(loginForm.get('password')?.dirty ||
										loginForm.get('password')?.touched)
								"
							>
								<small
									class="text-primaryRed text-md mt-2"
									*ngIf="loginForm.get('password')?.hasError(CustomValidationType.required)"
								>
									{{
										"authentication.errors.required"
											| translate
									}}
								</small>
								<small
									class="text-primaryRed text-md mt-2"
									*ngIf="loginForm.get('password')?.hasError(CustomValidationType.patternValidator) &&
									!loginForm.get('password')?.hasError(CustomValidationType.required)"
								>
								{{
									"authentication.errors.password_rules"
										| translate
								}}
								</small>
							</div>
	
							</div>
	
							<div class="__submit mt-6">
								<button
									type="submit"
									class="border border-solid py-2 rounded-lg w-full bg-primaryRed capitalize text-fontColor hover:bg-darkBlue hover:text-primaryRed transition-all border-primaryRed"
									(click)="signIn()"
								>
									{{
										"authentication.login.form.login"
											| translate
									}}
								</button>
							</div>
	
							<div class="__forgot-password">
								<a class="capitalize text-fontColor text-sm block mt-2 cursor-pointer hover:text-textYellow transition-all"
									routerLink="/auth/forgot-password">
									{{
										"authentication.login.form.forgot_password"
											| translate
									}}
								</a>
								<a
									[routerLink]="'/auth/register'"
									class="capitalize text-fontColor important-underline text-sm block mt-2 cursor-pointer hover:text-textYellow transition-all"
								>
									{{
										"authentication.login.form.dont_have_account"
											| translate
									}}
								</a>
							</div>
	
							<!-- <div class="__social-media text-center mt-5">
								<button
									type="button"
									(click)="signInWithGoogle()"
									class="__google relative border py-3 rounded-lg w-full hover:bg-inputFieldBackground transition-all hover:border-inputFieldBackground"
								>
									<h3
										class="text-fontColor capitalize text-sm font-normal"
									>
										{{
											"authentication.login.social_media.continue_with_google"
												| translate
										}}
									</h3>
									<img
										class="absolute left-5 top-1"
										src="assets/icons/google.svg"
										alt=""
									/>
								</button>
							</div> -->
						</form>

						<!-- FORM END -->
					</div>
				</div>
			</div>
		</main>
	</ng-template>
</p-dialog>