import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	inject,
} from "@angular/core";
import { LanguagesService } from "@sportyano/core/services/language services/languages.service";
import { PaginatorModule } from "primeng/paginator";

@Component({
	selector: "paggination",
	templateUrl: "./paggination.component.html",
	styleUrl: "./paggination.component.scss",
	standalone: true,
	imports: [PaginatorModule],
})
export class PaginationComponent implements OnInit {
	private _languageService = inject(LanguagesService);
	currentLang: string;
	ngOnInit(): void {
		this._languageService.language$.subscribe({
			next: (res) => {
				this.currentLang = res;
			},
		});
	}
	first: number = 0;

	@Output() pageChanged: EventEmitter<any> = new EventEmitter<any>();
	@Input() rows: number = 12;
	@Input() totalRecords: number = 0;

	onPageChange(e: any) {
		this.pageChanged.emit({ pageIndex: e.page + 1, pageSize: e.rows });
	}
}
