import { CommonModule } from "@angular/common";
import { inject, DestroyRef } from "@angular/core";
import { EventEmitter, OnInit, Output } from "@angular/core";
import { Component, Input } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { Photos } from "@sportyano/core/models/account/news";
import { ShowinterestService } from "@sportyano/core/services/generals/showinterest.service";
import { LanguagesService } from "@sportyano/core/services/language services/languages.service";
import { ShareModalComponent } from "@sportyano/shared/components/share-modal/share-modal.component";
import { CheckboxModule } from "primeng/checkbox";
@Component({
	selector: "cart-slider",
	templateUrl: "./cart-slider.component.html",
	styleUrl: "./cart-slider.component.scss",
	standalone: true,
	imports: [
		CommonModule,
		TranslateModule,
		ShareModalComponent,
		CheckboxModule,
		FormsModule,
	],
})
export class CartSliderComponent implements OnInit {
	@Input() isSelected: boolean = true;
	@Input() totalNumberOfItems: number = 100;
	@Input() currentItem: number = 1;
	@Input() index: number = 0;
	@Input() image: string;
	@Input() currentImage: string;
	@Input() isShowSelected: boolean = true;
	@Output() isSelectedChange = new EventEmitter<boolean>();
	@Output() nextClicked = new EventEmitter<number>();
	@Output() prevClicked = new EventEmitter<number>();
	@Output() deleteImage: EventEmitter<Photos> = new EventEmitter<Photos>();
	@Input() data: Photos;
	isHeart: boolean = false;
	visible: boolean = false;
	shareModel: boolean = false;
	currentLanguage: string = "ar";

	private destroyRef = inject(DestroyRef);
	constructor(
		private languagesService: LanguagesService,
		private showinterest: ShowinterestService
	) {}
	ngOnInit(): void {
		this.languagesService.language$.subscribe({
			next: (language: string) => {
				this.currentLanguage = language;
			},
		});
	}

	toggelHeart(e: any) {
		e.stopPropagation();
		this.isHeart = !this.isHeart;
		// this.showinterest.makePlayersInterest(1).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(data => {
		// })
	}

	share(e: any) {
		e.stopPropagation();
		this.shareModel = true;
	}

	onChildValueChange() {
		this.isSelected = !this.isSelected;
		this.isSelectedChange.emit(this.isSelected);
	}
	public deleteUserImage(data: Photos) {
		this.deleteImage.emit(data);
	}
}
