import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID, Inject, Component, Input, AfterViewInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-location-card',
  templateUrl: './location-card.component.html',
  styleUrls: ['./location-card.component.scss'],
})
export class LocationCardComponent implements AfterViewInit, OnDestroy {
  @Input() locationData: LocationData;

  public L: any = null; // Leaflet instance
  private map: any;
  public cities_json: any[] = []; // Store city data from cities_coordinates.json

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngOnDestroy(): void {
    if (this.map) {
      this.map.remove();
    }
  }

  async ngAfterViewInit(): Promise<void> {
    if (isPlatformBrowser(this.platformId)) {
      const leafletModule = await import('leaflet'); // Lazy load Leaflet
      this.L = leafletModule.default || leafletModule;

      // Load the cities JSON data using fetch
      await this.loadCitiesData();

      // Check if URL is shortened and zoom accordingly
      if (this.isShortUrl(this.locationData.location_url)) {
        this.zoomToCity(this.locationData.city_id);
      } else {
        this.zoomToLocation(this.locationData.lat, this.locationData.long);
      }
    }
  }

  // Load cities JSON file from assets using fetch
  private async loadCitiesData(): Promise<void> {
    try {
      const response = await fetch('assets/location-card/cities_coordinates.json');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      this.cities_json = await response.json();
    } catch (error) {
      console.error('Error loading cities data:', error);
    }
  }

  // Zoom to city based on city_id
  private zoomToCity(city_id: number): void {
    const city = this.cities_json.find(c => c.id === city_id);
    if (city) {
      this.displayMap(city.lat, city.long, 7);

      // Radar circles
      this.addRadarCircle(city.lat, city.long, 70000, 0.1);
      this.addRadarCircle(city.lat, city.long, 60000, 0.3);
      this.addRadarCircle(city.lat, city.long, 30000, 0.3);
      this.addRadarCircle(city.lat, city.long, 10000, 0.3);
    } else {
      // cannot find city, zooming into center of egypt
      this.displayMap(30.04566369071024, 31.23271424664213, 4);
      this.addRadarCircle(30.04566369071024, 31.23271424664213, 100000, 0.1);
      console.error('City not found for id:', city_id);
    }
  }

  // Add radar-style circle
  private addRadarCircle(lat: number, long: number, radius: number, opacity: number) {
    this.L.circle([lat, long], {
      color: 'red',
      fillColor: '#f03',
      fillOpacity: opacity,
      radius: radius,
      weight: 0,
    }).addTo(this.map);
  }

  // Zoom to the exact location and display a custom marker
  private zoomToLocation(lat: number, long: number): void {
    this.displayMap(lat, long);
    const customIcon = this.L.icon({
      iconUrl: './assets/images/location/marker-icon.png',  // Path to the custom marker image
      shadowUrl: './assets/images/location/marker-shadow.png',  // Path to the custom shadow image
      iconSize: [28, 45],
      iconAnchor: [12, 44],
      popupAnchor: [-3, -76],
      shadowSize: [68, 45],
      shadowAnchor: [22, 44]
    });

    this.L.marker([lat,long], { icon: customIcon })
      .addTo(this.map);
  }


  // Check if URL is shortened
  private isShortUrl(url: string): boolean {
    return url.includes('goo.gl') || url.length < 50;
  }

  // Display the map at the provided coordinates
  private displayMap(lat: number, long: number, zoomLevel: number = 13): void {
    if (!this.L) return; // Check if Leaflet is available

    this.map = this.L.map('map').setView([lat, long], zoomLevel);

    this.L.tileLayer('http://{s}.google.com/vt?lyrs=m&x={x}&y={y}&z={z}', {
      maxZoom: 18,
      minZoom: 3,
      subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
      zoomControl: false,
    }).addTo(this.map);
  }
}

interface LocationData {
  lat: number;
  long: number;
  mobile_number: string;
  address: string;
  branch_name: string;
  location_url: string;
  city_name: string;
  city_id: number;
  country_name: string;
}
