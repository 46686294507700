import { DatePipe, isPlatformBrowser } from "@angular/common";
import {
	Component,
	Inject,
	Input,
	OnInit,
	PLATFORM_ID,
	effect,
	inject,
	signal,
} from "@angular/core";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Profile } from "@sportyano/core/models/account/account";
import { UserData } from "@sportyano/core/models/authentications/login-data.model";
import {
	UserType,
	permission,
} from "@sportyano/core/models/permissions/permission";
import { IProgramDetailsResponse } from "@sportyano/core/models/programs/program-details";
import {
	ITimeSlotRequest,
	TimeSlot,
} from "@sportyano/core/models/programs/time-slots.model";
import { ProgramsScheduleService } from "@sportyano/core/services/academy/programs-schedule/programs.schedule.service";
import { AuthService } from "@sportyano/core/services/authServices/auth.service";
import { BookingService } from "@sportyano/core/services/booking/booking.service";
import { PlayerService } from "@sportyano/core/services/playerProfile/player.service";
import { StateManagementService } from "@sportyano/core/services/state-managment/state-management.service";
import { ToasterMessagesService } from "@sportyano/core/services/toaster-messages/toaster-messages.service";
import {
	BOOKING_SCHEDULE,
	PAYMENT_METHOD,
	PaymentMethodsType,
} from "@sportyano/main-site/booking/booking/models/const/booking.const";
import { IBookingRequest } from "@sportyano/main-site/booking/booking/models/interfaces/booking.interface";

@Component({
	selector: "app-program-schedule",
	templateUrl: "./program-schedule.component.html",
	styleUrl: "./program-schedule.component.scss",
	providers: [DatePipe],
})
export class ProgramScheduleComponent implements OnInit {
	@Input() typeOfProgram: "academy" | "playground";
	// INJECT
	private _router = inject(Router);
	private _fb = inject(FormBuilder);
	private _stateManagementService = inject(StateManagementService);
	private _bookingService = inject(BookingService);
	private _programSchedule = inject(ProgramsScheduleService);
	public translateService = inject(TranslateService);
	public weekDays: {
		label: string;
		value: number;
	}[];

	// FORMS
	public paymentMethodForm = this._fb.group<{
		payment_method: FormControl<number | null>;
	}>({
		payment_method: this._fb.control(PAYMENT_METHOD.visa, Validators.required),
	});
	// Public
	public PAYMENT_METHOD = PAYMENT_METHOD;
	userDate: UserData;
	// chosenScheduleDates: any = [];
	viewerProfiles: Profile[] = [];
	viewerSelectedId: number;
	storedSubmittedViewerProfile: string | undefined;
	userType: UserType;

	availableTimeSlots: TimeSlot[] = [];
	selectedStartingDate: string;
	methodeType: string = "cash";
	step: number = 1;
	permission = permission;
	programId: string | null;
	programDetails: any;
	storedSubmittedDatesForReview: Array<number | string> = [];
	playerInfoData: Profile = {} as Profile;
	playgroundBooking: string[] = [];
	daysOff: number[] = [];
	// Signals
	public selectedSlots = signal<Array<ITimeSlotRequest>>([]);
	// public
	todayDate: Date = new Date();
	public BOOKING_SCHEDULE = BOOKING_SCHEDULE;
	public bookingPrice: number = 0;
	constructor(
		@Inject(PLATFORM_ID) private platform: object,
		private _authService: AuthService,
		private activatedRoute: ActivatedRoute,
		private datePipe: DatePipe,
		private toastr: ToasterMessagesService,
		private playerService: PlayerService
	) {
		this.weekDays = [
			{
				label: "Saturday",
				value: 6,
			},
			{
				label: "Sunday",
				value: 0,
			},
			{
				label: "Monday",
				value: 1,
			},
			{
				label: "Tuesday",
				value: 2,
			},
			{
				label: "Wednesday",
				value: 3,
			},
			{
				label: "Thursday",
				value: 4,
			},
			{
				label: "Friday",
				value: 5,
			},
		];
		effect(() => {
			this._calculatePrice(this.selectedSlots());
		});
	}
	ngOnInit(): void {
		this.paymentMethodForm.get("payment_method")?.valueChanges.subscribe({
			next: (r) => {
				console.log("PAYMENY", r);
			},
		});
		this.userType = this._authService.getUserType();
		this.userDate = this._authService.getUserData();
		this.programId = this.activatedRoute.snapshot.params["id"];
		this.getAvailableTimeSlots(this.programId);
		this.getProgramDetails(this.programId);
		if (this.userType === permission.viwer) {
			this.getViewerProfiles();
		}

		if (this.userType === permission.player) {
			this.getPlayerProfileInfo();
		}
	}
	private getViewerProfiles() {
		this.playerService.getPlayerInfoData("viewers", 1).subscribe({
			next: (res) => {
				this.viewerProfiles = res.profiles;
				if (this.viewerProfiles.length > 0) {
					this.viewerSelectedId = this.viewerProfiles[0].id;
				}
			},
		});
	}

	private getPlayerProfileInfo() {
		this.playerService
			.getPlayerInfoData("players", this.userDate.id)
			.subscribe({
				next: (res) => {
					this.playerInfoData = res.data;
				},
			});
	}

	get daysOffLabel(): string {
		if (!this.programDetails?.days_off || this.programDetails.days_off.length === 0) {
		  return '';
		}
		
		if (this.translateService.currentLang === 'en') {
		  return this.programDetails.days_off
			.map((day: number) => this.getDayLabel(day))
			.join(', ');
		} else {
		  return this.programDetails.days_off
			.map((day: number) => this.translateService.instant(`account.academy_programs_schedule.week_days.${this.getDayLabel(day)}`))
			.join(', ');
		}
	  }
	  
	  getDayLabel(day: number): string {
		const foundDay = this.weekDays.find(weekDay => weekDay.value === day);
		return foundDay ? foundDay.label : '';
	  }
	  

	getAvailableTimeSlots(programId: string | null) {
		if (programId) {
			this._bookingService
				.getProgramTimeSlots(programId, this.typeOfProgram)
				.subscribe({
					next: (res) => {
						this.availableTimeSlots = res.slots;
					},
				});
		}
	}

	getProgramDetails(programId: string | null) {
		if (programId) {
			this._bookingService
				.getProgramDetails(programId, this.typeOfProgram)
				.subscribe({
					next: (res: any) => {
						if (res.program) {
							console.log(res);
							this.programDetails = res.program;
							this.selectedStartingDate =
								this.programDetails["start_date"];
						} else {
							this.programDetails = res.Playground;

							if (this.programDetails.days_off) {
								this.daysOff = this.programDetails.days_off;
							}
							this.playgroundBookingSlots(
								res.Playground.time_from,
								res.Playground.time_to
							);
						}
					},
				});
		}
	}

	submitSchedule() {
		if (this.selectedSlots().length === 0) {
			this.toastr.showWarning(
				this.translateService.instant("chosen_dates_validation")
			);
			return;
		}

		if (
			this.typeOfProgram === permission.academy &&
			this.selectedSlots().length !==
				this.programDetails.number_of_sessions
		) {
			this.toastr.showWarning(
				this.translateService.instant("dates_should_be_selected") +
					" " +
					this.programDetails.number_of_sessions +
					" " +
					this.translateService.instant("dates")
			);
			return;
		}

		if (this.userType === permission.viwer && !this.viewerSelectedId) {
			this.toastr.showWarning(
				this.translateService.instant("profile_booking_validation")
			);
			return;
		}

		if (this.userType === permission.player && !this.playerInfoData) {
			this.toastr.showWarning(
				this.translateService.instant("profile_booking_validation")
			);
			return;
		}

		this.step = 2;
	}

	returnBack(){
		this._router.navigate([`/main/academies/${this.programDetails.branch.slug}`], {
			queryParams: {},
		});
	}
		

	private _academyProgramBooking(payload: IBookingRequest) {
		this._programSchedule
			.programBooking(this.programId as string, payload)
			.subscribe({
				next: (res) => {
					this.scrollToTopView();
					if (res.status == "success") {
						this._bookingNavigation({
							payment_url: res.payment_url,
							payment_method: payload.payment_method,
						});
					}
				},
			});
	}

	private _playgroundBooking(payload: IBookingRequest): void {
		this._programSchedule
			.playgroundBooking(this.programId as string, payload)
			.subscribe({
				next: (res) => {
					this.toastr.showSuccess(res.message);
					if (res.status == "success") {
						this._bookingNavigation({
							payment_url: res.payment_url,
							payment_method: payload.payment_method,
						});
					}
					// STORE
					this.storedSubmittedDatesForReview = [
						...this.selectedSlots().map((ele: ITimeSlotRequest) => {
							return this.typeOfProgram == this.permission.academy
								? (ele.slotId as number)
								: ele.slot;
						}),
					];
				},
			});
	}

	private _navigateToBooking(): void {
		this._router.navigate(["/main/booking"], {
			queryParams: {},
		});
	}
	private scrollToTopView() {
		if (isPlatformBrowser(this.platform)) {
			window.scroll(0, 0);
		}
	}

	private playgroundBookingSlots(timeFrom: number = 10, timeTo: number = 24) {
		this.playgroundBooking = [];
		for (let i = timeFrom; i <= timeTo; i++) {
			this.playgroundBooking.push(
				`${this.getTimePeriod(i)} - ${this.getTimePeriod(i + 1)}`
			);
		}
	}

	private getTimePeriod(time: number): string {
		if (time === 0 || time === 24) {
			return `12AM`;
		}
		if (time === 12) {
			return `${time}PM`;
		} else {
			return time > 12 ? `${time - 12}PM` : `${time}AM`;
		}
	}

	public setPaymentMethod(paymentMethod: PaymentMethodsType): void {
		this.paymentMethodForm.get("payment_method")?.setValue(paymentMethod);
	}

	public onBooking() {
		const payload: any = {
			starting_date: this.selectedStartingDate,
			...(this.selectedSlots().filter(
				(ele: ITimeSlotRequest) => ele.slotId !== undefined
			).length > 0 && {
				time_slots: this.selectedSlots()
					.filter((ele: ITimeSlotRequest) => ele.slotId !== undefined)
					.map((ele: ITimeSlotRequest) => ele.slotId),
			}),
			...(this.selectedSlots().filter(
				(ele: ITimeSlotRequest) =>
					ele.slotId === undefined && ele.slot !== undefined
			).length > 0 && {
				slots: this.selectedSlots()
					.filter(
						(ele: ITimeSlotRequest) =>
							ele.slotId === undefined && ele.slot !== undefined
					)
					.map((ele: ITimeSlotRequest) => ele.slot),
			}),
			payment_method: this.paymentMethodForm.get("payment_method")?.value,
		};

		if (
			this.userType === permission.viwer &&
			this.viewerProfiles.length > 0
		) {
			payload.profile_id = this.viewerSelectedId;
			this.storedSubmittedViewerProfile = this.viewerProfiles.filter(
				(ele) => ele.id === this.viewerSelectedId
			)[0].name;
		}

		this._stateManagementService.setSessionStorage("booking", {
			...this.programDetails,
			payloadData: payload,
			bookingType: this.typeOfProgram,
			selectedSlots: this.selectedSlots(),
			bookingPrice:
				this.typeOfProgram == BOOKING_SCHEDULE.playground
					? this.bookingPrice
					: this.programDetails?.price,
		});

		if (this.typeOfProgram === permission.academy) {
			this._academyProgramBooking(payload);
		} else {
			this._playgroundBooking(payload);
		}
	}

	private _bookingNavigation(payload: {
		payment_url: string | null;
		payment_method: PaymentMethodsType;
	}): void {
		if (payload.payment_method === PAYMENT_METHOD.cash) {
			this._navigateToBooking();
			return;
		} else if (payload.payment_url) {
			this._bookingService.navigateToPaymobPayment(payload.payment_url);
		}
	}

	private _calculatePrice(selectedSlots: Array<ITimeSlotRequest>): void {
		if (
			this.programDetails?.price &&
			this.typeOfProgram == BOOKING_SCHEDULE.playground
		) {
			this.bookingPrice = selectedSlots.reduce(
				(total, slot) => total + slot?.price!,
				0
			);
		}
	}
}
