<cart
	[title]="academy.branch_name"
	[image]="
		academy.cover_image ?? '../../assets/images/image/academy-cover.jpg'
	"
	[route]="'/main/academies/' + academy.slug"
	[type]="'academyandplayground'"
	[height]="270"
>
	<div postContent class="flex flex-col ml-2">
		 <ng-container *ngIf="cartComponent"> 
			<!-- branch_name -->
			<!-- <ng-container
				*ngTemplateOutlet="
					cartComponent?.itemDataTemplate!;
					context: {
						primeIcon: 'pi-warehouse',
						value: academy?.branch_name
					}
				"
			></ng-container> -->
		 </ng-container> 
		<p
			TextLimiter
			StopClickPropagation
			[maxChars]="50"
			[seeMoreText]="'text.seeMore' | translate"
			class="desc break-large-text mt-3 text-fontColor text-sm word-break-word z-50"
		>
			{{ academy?.description }}
		</p>
		<ul
			class="exercises mt-5 flex flex-col justify-start gap-3 list-none text-white"
		>
			@for(sport of academy?.sports; track $index){
			<chip colorHex="#f9c728" [label]="sport.name"></chip>

			}

		

			<!-- address -->
			<ng-container
				*ngTemplateOutlet="
					cartComponent?.itemDataTemplate!;
					context: {
						primeIcon: 'pi-map-marker',
						value:
							academy?.city_name + ' , ' + academy?.country_name
					}
				"
			></ng-container>
		</ul>
	</div>
</cart>
