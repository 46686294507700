import {
	HttpClient,
	HttpEvent,
	HttpEventType,
	HttpResponse,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IUserImages } from "@sportyano/account/components/news-and-media/photos/models/interfaces/user-images.interface";
import { IUserVideos } from "@sportyano/account/components/news-and-media/videos/models/interfaces/user-videos.interface";
import { News, Photos, Videos } from "@sportyano/core/models/account/news";
import { CommonService } from "@sportyano/core/services/shard/common.service";
import {
	Observable,
	Subject,
	catchError,
	finalize,
	interval,
	map,
	mergeMap,
	of,
	switchMap,
	takeUntil,
	takeWhile,
	tap,
	throwError,
} from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
	providedIn: "root",
})
export class NewsService {
	private _destroy$: Subject<null> = new Subject();
	constructor(
		private commonServices: CommonService,
		private _httpcl: HttpClient
	) {}
	getNews(model: any) {
		return this.commonServices.CommonGetRequestsWithQuery(
			`${environment.baseUrl}players/news`,
			model
		);
	}

	updateNewsWithImages(model: any, new_Id: number, file: FormData) {
		return this.commonServices.uploadFile(file).pipe(
			switchMap((res: any) => {
				model.image_url = res.path;
				return this.commonServices.CommonPutRequests(
					model,
					`${environment.baseUrl}players/news/${new_Id}`
				);
			})
		);
	}

	updateNews(model: any, new_Id: number) {
		return this.commonServices.CommonPutRequests(
			model,
			`${environment.baseUrl}players/news/${new_Id}`
		);
	}

	addNews(model: News, file: FormData) {
		return this.commonServices.uploadFile(file).pipe(
			switchMap((res: any) => {
				model.image_url = res.path;
				return this.commonServices.CommonPostRequests(
					model,
					`${environment.baseUrl}players/news`
				);
			})
		);
	}

	deleteNews(new_Id: number) {
		return this.commonServices.CommonDeleteRequest(
			`${environment.baseUrl}players/news/${new_Id}`
		);
	}

	//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	getPhotos(routerName: string, player_id: number):Observable<IUserImages> {
		return this.commonServices.CommonGetRequests(
			`${environment.baseUrl}${routerName}/${player_id}/images`
		);
	}


	public getVideos(
		routerName: string,
		player_id: number
	): Observable<IUserVideos> {
		return this.commonServices.CommonGetRequests(
			`${environment.baseUrl}${routerName}/${player_id}/videos`
		);
	}
	updatePhotossWithImages(
		routerName: string,
		model: Photos,
		photo_Id: number,
		file: FormData
	) {
		return this.commonServices.uploadFile(file).pipe(
			switchMap((res: any) => {
				model.path = res.path;
				return this.commonServices.CommonPutRequests(
					model,
					`${environment.baseUrl}${routerName}/photos/${photo_Id}`
				);
			})
		);
	}

	updatePhotos(routerName: string, model: Photos, photo_Id: number) {
		return this.commonServices.CommonPutRequests(
			model,
			`${environment.baseUrl}${routerName}/photos/${photo_Id}`
		);
	}

	addPhotos(
		routerName: string,
		model: Photos,
		player_id: number,
		file: FormData
	) {
		return this.commonServices.uploadFile(file).pipe(
			switchMap((res: any) => {
				model.path = res.path;
				return this.commonServices.CommonPostRequests(
					model,
					`${environment.baseUrl}${routerName}/${player_id}/images `
				);
			})
		);
	}

	// add videos ahmed omar update 2-6

	addVideos(
		routerName: string,
		model: Videos,
		player_id: number,
		data: FormData
	) {
		return this._httpcl
			.post(
				`${environment.baseUrl}${routerName}/${player_id}/videos`,
				data,
				{
					reportProgress: true,
					observe: "events",
				}
			)
			.pipe(
				mergeMap((event) => {
					if (event instanceof HttpResponse) {
						// Upload complete
						this._destroyUploadedProgress();
						return of(event);
					} else {
						return this._fakeProgress();
					}
				}),
				catchError((err) => {
					this._destroyUploadedProgress();
					return throwError(() => err);
				})
			);
	}

	deletePhotos(routerName: string, player_id: number, image_id: number) {
		return this.commonServices
			.CommonDeleteRequest(`${environment.baseUrl}${routerName}/${player_id}/images/${image_id}
    `);
	}

	private _fakeProgress(): Observable<number> {
		const totalDuration = Math.floor(Math.random() * 150000) + 30000; //duration between 30s and 3m
		const steps = totalDuration / 1000; //  1-second intervals
		let progress = 0;

		return new Observable<number>((observer) => {
			const updateProgress = () => {
				const increment = Math.floor(Math.random() * 5) + 1; // Random increment between 1 and 5
				progress = Math.min(progress + increment, 95);
				observer.next(progress);
				if (progress < 95) {
					setTimeout(updateProgress, 1000); // Update every 1 second
				} else {
					observer.complete();
				}
			};

			updateProgress();
		}).pipe(takeUntil(this._destroy$));
	}

	private _destroyUploadedProgress() {
		this._destroy$.next(null);
		this._destroy$.complete();
		this._destroy$.next(null);
		this._destroy$.complete();
	}
}
